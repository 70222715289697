import type { ThemeDefinition } from 'vuetify';

export const myCustomLightTheme: ThemeDefinition = {
  dark: false,
  colors: {
    'dark-color': '#15141A',
    'dark-second': '#373545',
    'error': '#FF5C5C',
    'error-bg': '#FFE4E4',
    'green': '#18C0473D',
    'inactive': '#48455F',
    'input-bg': '#FBFBFD',
    'input-border': '#767676',
    'input-label': '#373545',
    'light': '#D7D6E1',
    'light-gen': '#5018E93D',
    'light-secondary': '#B4B2C7',
    'paragraph-mid': '#6D6A8D',
    'primary': '#5018E9',
    'primary-light': '#F2F2FF',
    'secondary': '#E9E7FF',
    'secondary-lighter': '#EEEDF1',
    'shadow': '#0000001F',
    'success': '#18E920',
    'success-message': '#D6D2FF',
    'yellow': '#D0D34A3D',
    'gray': '#8B88A8'
  }
};
