export const APOLLO_TOKEN = 'apollo-token';

export const PRODUCT_FORM_STEPS = ['forms', 'generated'];

export const PRODUCT_STATUSES = ['DRAFT', 'AWAITING PUBLISH', 'PUBLISHED', 'GENERATING'];

export const STORE_ID = 'store-id';

export const LAST_OPENED_PANEL = 'last-opened-panel';

export const DIALOG_TYPE = {
  ACCOUNT: 'account',
  STORE: 'store'
} as const;

export const PRODUCT_REPORT_STATES = {
  FAILED: 'FAILED',
  IMPORTING: 'IMPORTING',
  COMPLETED: 'COMPLETED'
} as const;

export const SUPPORT_EMAIL = 'contact@venturelabs.team';
